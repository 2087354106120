import { combineReducers } from 'redux'
import userReducer from './userslice';
import postsReducer from './perusahaan'
import roleReducer from './roles'; 
import produkReducer from './produkSlice'; 
import fetchReducer from './fetchSlice';


// import postReducer from './post'
// import commentsReducer from './comments'

const rootReducer = combineReducers({
    posts: postsReducer,
    users : userReducer,
    roles : roleReducer,    
    produks : produkReducer,    
    fetchGeneral : fetchReducer,
})

export default rootReducer
