import api from './api';

export function getTipeProduk(){
    return api.get(`/master/api/tipe-produk`).then(res => res.data);
}

export function getProduk(keyword, limit, offset){
    return api.get(`/master/api/produk?${keyword}`, {params:{limit, offset}}).then(res => res.data);
}

export function postProduk(form){
    return api.post(`/master/api/produk/`, form).then(res => res.data);
}


export function editProduk(id){
    return api.get(`/master/api/produk/${id}`).then(res => res.data);
}

export function updateProduk(form, id){ // edit data yang baru pakai coreui
    return api.put(`/master/api/produk/${id}`, form).then(res => res.data);
}

// inventory ====================
export function postPenyerahanInventory(form){
    return api.post(`/transaksi/api/pemakaian-inventory/`, form).then(res => res.data);
}

export function postPengembalianInventory(form, id){
    return api.put(`/transaksi/api/pengembalian-inventory/${id}`, form).then(res => res.data);
}



export function getProdukLog(keyword, limit, offset){
    return api.get(`/transaksi/api/produk-log/?${keyword}`, {params:{limit, offset}}).then(res => res.data);
}

export function getProdukSatuan(keyword, limit, offset){
    return api.get(`/master/api/produk-satuan/?${keyword}`, {params:{limit, offset}}).then(res => res.data);
}

export function getProdukDetail(keyword, limit, page){
    return api.get(`/produk/api/produk/view-detail?${keyword}`, {params:{limit, page}}).then(res => res.data);
}


export function deleteData(id){
    return api.delete(`/produk/api/produk/${id}`).then(res => res.data);
}

export function deleteProdukDetail(id){
    return api.delete(`/master/api/produk/${id}`).then(res => res.data);
}







export function getStock(produk_id){
    return api.get(`/produk/api/get-produk-stock?produk_id=${produk_id}`)
}



// upload foto
export function uploadFotoProduk(formData){
    return api.post(`/produk/api/produk/foto`, formData).then(res => res.data);
}

export function changePrimaryFotoProduk(formData, id){
    return api.put(`/produk/api/produk/foto/${id}`, formData).then(res => res.data);
}

export function deleteFotoProduk(id){
    return api.delete(`/produk/api/produk/foto/${id}`).then(res => res.data);
}



// produk_satuan
export function postPenyesuaianProduk(formData){
    return api.post(`/produk/api/penyesuaian-stock`, formData).then(res => res.data);
}

export function getPenyesuaianProduk(keyword, limit, page){
    return api.get(`/produk/api/penyesuaian-stock${keyword}`, {params:{limit, page}}).then(res => res.data);
}