import React, { useState, useEffect, useCallback } from 'react';
import * as ut from '../utils/utils';
const UserContext = React.createContext([{}, () => {}]);


  
const UserProvider = (props) => {      
    const [state, setState] = useState({
        listPenjualan : ut.getLocalStorage("listPenjualan", [])
    });

    useEffect(() => {
        ut.setLocalStorage("listPenjualan", state.listPenjualan);
    }, [state.listPenjualan]);

    const setListPenjualanValue = useCallback(() => {
        // ut.setLocalStorage("listPenjualan", post);
        // console.log('post is  asdfasdf>>>>', post);
        const myData = ut.getLocalStorage('listPenjualan');
        setState(state => ({...state, listPenjualan : myData}))
        console.log('myData is >>>', myData);
    },[]);

    // useEffect(() => {
    //     setListPenjualanValue();
    // },[setListPenjualanValue])

    const stateFunction = {
        setListPenjualanValue : setListPenjualanValue,
    }



  

    return (
        <UserContext.Provider value={{            
            stateContext: [state, setState], 
            stateFunction: stateFunction,
        }}>
            {props.children}
        </UserContext.Provider>
    )
}
export { UserContext, UserProvider};
