import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';


// import './style/customStyle.css';

import { unregister } from './serviceWorker';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const LoginPage = React.lazy(() => import('./views/Authentication/LoginPage'))
// const CetakMasterBill = React.lazy(() => import('./views/Room/Room/CetakMasterBill'));
unregister();

const App = () => {
	return (
		
		<HashRouter>
			<React.Suspense fallback={loading()}>
				<Switch>
					<Route exact path="/login" name="Login Page" render={props => <LoginPage {...props} />} />					
					<Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
				</Switch>
			</React.Suspense>
		</HashRouter>		
	);
}

export default App;
