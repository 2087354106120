export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        // development: "http://127.0.0.1:8000",
        // production: "http://127.0.0.1:8000", 
        development: "https://dataatk.myappscenter.com",
        production: "https://dataatk.myappscenter.com", 
    },
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__renataKidsUser",
      ACCESS_TOKEN: "__renataKidsValue",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm",
      DEF_FB : "__defFb", 
    },
    tokenName : 'fisipAtk',
    expiresSession : 86400,
    itemPerPage : 10,
    timeOutValue : 5000,
    endPoint : {
      kategori : `/master/api/kategori/`,
      department : `/master/api/department/`,
      requestProduk : `/transaksi/api/request-produk/`,
      barangMasuk : `/transaksi/api/barang-masuk/`,
      produk : `/master/api/produk/`,
      inventory : `/master/api/inventory/`,
      changePassword : `/auth/api/change-password`,
    }
};


